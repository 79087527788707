import React from 'react'
import about from '../img/NerdX_Digital_Qui_sommes_nous.avif'

const About = () => {
    return (
        <section className="slider-stwo valign position-re">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="img">
                            <img src={about} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 valign">
                        <div className="cont">
                            <div className="sub-title mb-5">
                                <h6>Pourquoi se digitaliser de mannière éco-responsable</h6>
                            </div>
                            <h1 className="mb-10 fw-700">+durable +économique +performantes +écolo</h1>
                            <p>Chez Nerdx Digital, nous croyons que vous pouvez digitaliser votre activité, 
                                sans nuire à notre planète (et contribuer facilement à cette urgence climatique). <h6>Notre vision est de digitaliser l'Afrique, le monde sans polluer l'environnement. 
                                Ainsi nous concevons et développons des solutions bas-carbones, en s’attaquant à leur consommation énergétique, leur émission de CO2, leur consommation d’eau ainsi que leur performance.</h6>
                            </p>
                            <ul>
                                <li>
                                    <div>
                                        <span className="icon pe-7s-cloud-download">
                                            <div className="bord"></div>
                                        </span>
                                    </div>
                                    <div className="cont">
                                        <h6>Eco-conception web</h6>
                                        <p>Des solutions digitales faibles en C02, en energie et 10x performantes.</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span className="icon pe-7s-display1">
                                            <div className="bord"></div>
                                        </span>
                                    </div>
                                    <div className="cont">
                                        <h6>Green IT</h6>
                                        <p>Des solution digitales pour l'urgence climatique.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="line bottom right"></div>
        </section>
    )
}

export default About