import React from 'react'
import Nav from '../component/nav'
import Footer from '../component/footer'
import '../css/plugins.css'
import '../css/style.css'
import design from '../img/icon-design.svg'
import app from '../img/app.webp'
import mesure from '../img/icon-mesure.svg'
import hosting from '../img/icon-hosting.webp'
import formation from '../img/icon-formation.svg'


const ExpertiseLayout = () => {
    return (
        <div>
            <Nav />
            <header class="works-header valign sub-bg">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-9 static">
                            <div class="capt mt-50">
                                <div class="parlx text-center">
                                    <h1 class="color-font">Conception web</h1>
                                    <p>La digitalisation aujourd’hui n’est plus vraiment un choix : elle s’impose, l'ecologie aussi.
                                        <br/>Notre mission est d’accompagner vos projets numériques, et de mieux les concevoir.</p>
                                </div>

                                <div class="bactxt custom-font valign">
                                    <span class="full-width">ECO</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div class="main-content">
                <section class="blog-pg blog-list section-padding pt-0">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8 col-md-10 pt-50">
                                <div class="">
                                    <h3 class="wow fadeIn color-font" data-wow-delay=".5s">Éco-conception digitale</h3>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-11">
                                <div class="posts mt-20">
                                    <div class="item mb-80 wow fadeInUp" data-wow-delay=".3s">
                                        <div class="row">
                                            <div class="col-lg-9 valign">
                                                <div class="cont">
                                                    <div>
                                                        <h4 class="title">Éco-conception ou refonte de sites internet</h4>
                                                        <p>Nous concevons des sites web vitrines, des sites e-commerces, des sites corporates, des plateformes web, 
                                                        des bases de données et déployons des solutons métier selon le besoin. 
                                                        Nous développons et optimisons tous les éléments du site ou de l'application en termes de design, 
                                                        de contenus, de code et d'hébergement.
                                                    </p>
                                                    <a href="#" class="butn bord curve mt-30">Voir notre catalogue</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 valign">
                                                <div class="img md-mb50">
                                                    <img src={design} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item mb-80 wow fadeInUp" data-wow-delay=".3s">
                                        <div class="row">
                                            <div class="col-lg-3 valign">
                                                <div class="img md-mb50">
                                                    <img src={app} alt="" />
                                                </div>
                                            </div>
                                            <div class="col-lg-9 valign">
                                                <div class="cont">
                                                    <div>
                                                        <h4 class="title">Application web ou mobile et PWA</h4>
                                                    <p>Parce qu'il y a de plus en plus d'utilisateur mobile, élargissez votre cible en etant présent dans le smartphone de vos clients et prospects à travers des applications web, native, ou hybrid performantes et éco-responsable.
                                                        Ici notre mission est d’accompagner vos projets de conception d'application (declinaison de site web, PWA, android, IOS, métier), et de mieux les concevoir pour vous.
                                                    </p>
                                                    <a href="#" class="butn bord curve mt-30">Voir notre catalogue</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-8 col-md-10 pt-50">
                                <div class="">
                                    <h3 class="wow fadeIn color-font" data-wow-delay=".5s">Mesure et analyse de l'empreinte carbone du numérique</h3>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-11">
                                <div class="posts mt-20">
                                    <div class="item mb-80 wow fadeInUp" data-wow-delay=".3s">
                                        <div class="row">
                                            <div class="col-lg-9 valign">
                                                <div class="cont">
                                                    <div>
                                                        <h4 class="title">Audit de l'éco-conception web</h4>
                                                    <p>Audit basé sur la combinaison de 2 différentes mesures : la performance énergétique et l'impact environnemental. Cette analyse complète est effectuée sur tous les éléments qui composent un site internet ou une application mobile, en mesurant la consommation énergétique au chargement, 
                                                        quand le site n'est pas utilisé ou quand des interactions avec le back office se produisent.
                                                    </p>
                                                    <a href="#" class="butn bord curve mt-30">En savoir plus</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 valign">
                                                <div class="img md-mb50">
                                                    <img src={mesure} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item mb-80 wow fadeInUp" data-wow-delay=".3s">
                                        <div class="row">
                                            <div class="col-lg-3 valign">
                                                <div class="img md-mb50">
                                                    <img src={mesure} alt="" />
                                                </div>
                                            </div>
                                            <div class="col-lg-9 valign">
                                                <div class="cont">
                                                    <div>
                                                        <h4 class="title">Analyse et recommandations</h4>
                                                    <p>Analyse de l’impact environnemental et énergétique de chaque élément du site : design, contenus, code et hébergement. Grâce aux données collectées, nous établissons une liste de recommandations cartographiées suivant 2 variables : 
                                                        indice de complexité de la tâche vs. gain potentiel d’éco-conception.
                                                    </p>
                                                    <a href="#" class="butn bord curve mt-30">En savoir plus</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-8 col-md-10 pt-50">
                                <div class="">
                                    <h3 class="wow fadeIn color-font" data-wow-delay=".5s">Hébergement web et cloud éco-responsable</h3>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-11">
                                <div class="posts mt-20">
                                    <div class="item mb-80 wow fadeInUp" data-wow-delay=".3s">
                                        <div class="row">
                                            <div class="col-lg-9 valign">
                                                <div class="cont">
                                                    <div>
                                                    <p>Tout ce qu'il vous faut pour héberger et deployer des sites Internet et applications professionnels 
                                                        qui s'affichent à la vitesse de l'éclair. Hébergement éco-responsable, sécurisé, et à forte performance, car nous utilisons de l'énergie renouvelable et que nos centres de données sont refroidis avec de l'air extérieur naturel. Notre Hébergement vous offre toute la puissance 
                                                        et les technologies dont vous avez besoin pour réussir vos projets en ligne.
                                                    </p>
                                                    <a href="#" class="butn bord curve mt-30">En savoir sur nos offres d'hébergement</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 valign">
                                                <div class="img md-mb50">
                                                    <img src={hosting} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-8 col-md-10 pt-50">
                                <div class="">
                                    <h3 class="wow fadeIn color-font" data-wow-delay=".5s">Sensibilisation et accompagnement à l'éco-conception numérique</h3>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-11">
                                <div class="posts mt-20">
                                    <div class="item mb-80 wow fadeInUp" data-wow-delay=".3s">
                                        <div class="row">
                                            <div class="col-lg-3 valign">
                                                <div class="img md-mb50">
                                                    <img src={formation} alt="" />
                                                </div>
                                            </div>
                                            <div class="col-lg-9 valign">
                                                <div class="cont">
                                                    <div>
                                                        <h4 class="title">Accompagnement sur-mesure</h4>
                                                    <p>L’éco-conception commence dès la première génération d’idées. Elle se prépare en amont et s’intègre tout au long du projet. C’est pourquoi, nous pouvons accompagner les équipes sur les différentes étapes d’un projet, en fonction des contraintes établies. 
                                                        Cela permet d’assurer un degré d’engagement et des résultats de performance au plus proche de vos besoins et de la réalité du projet.
                                                    </p>
                                                    <a href="#" class="butn bord curve mt-30">Voir notre catalogue</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item mb-80 wow fadeInUp" data-wow-delay=".3s">
                                        <div class="row">
                                            <div class="col-lg-9 valign">
                                                <div class="cont">
                                                    <div>
                                                        <h4 class="title">Formation des équipes à l'éco-conception web</h4>
                                                    <p>Parce qu'il est important d'embarquer les équipes sur des bases communes, nous les formons à l'éco-conception digitale en leur apportant des solutions et alternatives concrètes pour améliorer l'efficacité énergétique 
                                                        et les performances environnementales de vos sites et applications selon les standards du web et l'évolution des technologies.
                                                    </p>
                                                    <a href="#" class="butn bord curve mt-30">En savoir plus sur nos offres de formations</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 valign">
                                                <div class="img md-mb50">
                                                    <img src={app} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item mb-80 wow fadeInUp" data-wow-delay=".3s">
                                        <div class="row">
                                            <div class="col-lg-3 valign">
                                                <div class="img md-mb50">
                                                    <img src={app} alt="" />
                                                </div>
                                            </div>
                                            <div class="col-lg-9 valign">
                                                <div class="cont">
                                                    <div>
                                                        <h4 class="title">Sensibilisations et Conférences</h4>
                                                    <p>Notre mission est aussi de clarifier l'impact environnemental du numérique et de partager notre vision d'un numérique plus durable et résilient. C'est dans ce but que nous organisons des conférences sur-mesure adaptées à vos besoins et votre audience avec pour objectif de sensibiliser aux problématiques environnementales des nouvelles technologies.
                                                    </p>
                                                    <a href="#" class="butn bord curve mt-30">En savoir plus sur nos offres de formations</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="call-action section-padding bg-img" data-background="img/patrn.svg">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-8 col-lg-9">
                                <div class="content sm-mb30">
                                    <h6 class="wow" data-splitting>Chez nous</h6>
                                    <h2 class="wow" data-splitting>Vos ambitions n'ont<br/> <b class="back-color"> pas de limites.</b>.</h2>
                                </div>
                            </div>

                            <div class="col-md-4 col-lg-3 valign">
                                <a href="mailto:contact@nerdxdigital.com" class="butn bord curve wow fadeInUp" data-wow-delay=".5s"><span>Lancez votre maintenant</span></a>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer />
            </div>
            
            
        </div>
    )
}

export default ExpertiseLayout