import React from 'react'
import why from '../img/NerdX_Digital_why.webp'
import pattern from '../img/patrn.svg'


const Expertise = () => {
    return (
        <div>
            <section className="feat sub-bg section-padding" id="services">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-md-10">
                            <div className="sec-head">
                                <h6 className="wow fadeIn" data-wow-delay=".5s">Nos Expertises</h6>
                                <h3 className="wow color-font">Concevoir, développer, héberger, déployer et maintenir des solutions numériques utiles et éco-responsables</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 items md-mb30">
                            <div className="item wow fadeIn" data-wow-delay=".3s">
                                <span className="icon">
                                    <i className="ion-ios-monitor"></i>
                                </span>
                                <h5>Eco-conception Digital (site web, e-commerce, app web & Mobile)</h5>
                                <p>Nous développons ou optimisons tous les éléments du site ou de l'application en termes de design, de contenus, de code et d'hébergement.</p>
                                <a href="" onClick={(e) => {e.preventDefault()}} className="more-stroke"><span></span></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 items active md-mb30">
                            <div className="item wow fadeIn" data-wow-delay=".3s">
                                <span className="icon">
                                    <i className="ion-ios-bolt-outline"></i>
                                </span>
                                <h5>Mesure et analyse de l'empreinte carbone du numérique</h5>
                                <p>Audit basé sur la combinaison de la performance énergétique et de l'impact environnemental.</p>
                                <a href="" onClick={(e) => {e.preventDefault()}} className="more-stroke"><span></span></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 items sm-mb30">
                            <div className="item wow fadeIn" data-wow-delay=".3s">
                                <span className="icon">
                                    <i className="ion-cube"></i>
                                </span>
                                <h5>L'hébergement web et cloud éco-responsable</h5>
                                <p>Notre solution d'hébergement écologique est fiable, sûre et rapide. 
                                    Elle est entièrement évolutive, de sorte que vous ne payez que pour ce dont vous avez besoin.</p>
                                <a href=""onClick={(e) => {e.preventDefault()}} className="more-stroke"><span></span></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 items">
                            <div className="item wow fadeIn" data-wow-delay=".3s">
                                <span className="icon">
                                    <i className="ion-ios-color-wand"></i>
                                </span>
                                <h5>Accompagnement à l'éco-conception numérique et Bilan carbone</h5>
                                <p>L’éco-conception commence dès la première génération d’idées. 
                                    Elle se prépare en amont et s’intègre tout au long du projet.</p>
                                <a href="" onClick={(e) => {e.preventDefault()}} className="more-stroke"><span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="min-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="img">
                                <img className="thumparallax-down" src={why} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 valign">
                            <div className="content">
                                <h4 className="color-font">Les avantages de collaborer avec nous</h4>
                                <p className="wow txt" data-splitting>Pourquoi choisir la digitalisation éco-responsable pour votre entreprise ?
                                </p>
                                <ul>
                                    <li className="wow fadeInUp" data-wow-delay=".2s">Améliorez votre image de marque en montrant votre engagement envers la durabilité et la responsabilité environnementale, ce qui peut vous démarquer de vos concurrents.</li>
                                    <li className="wow fadeInUp" data-wow-delay=".4s">Protégez notre planète en réduisant votre empreinte carbone grâce à des solutions numériques économes en énergie et en ressources.</li>
                                    <li className="wow fadeInUp" data-wow-delay=".6s">Boostez votre performance en ligne en offrant des expériences utilisateur optimisées qui convertissent et fidélisent vos clients.</li>
                                    <li className="wow fadeInUp" data-wow-delay=".8s">Contribuez à un avenir meilleur en encourageant l'adoption de pratiques numériques durables dans votre secteur d'activité.</li>
                                </ul>
                                <a href="about.html" onClick={(e) => {e.preventDefault()}} className="butn bord curve mt-40 wow fadeInUp"
                                    data-wow-delay=".8s"><span>Nous contacter</span></a><br />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="call-action section-padding bg-img" data-background={pattern}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-lg-9">
                            <div className="content sm-mb30">
                                <h6 className="wow" data-splitting>Parlons-en</h6>
                                <h2 className="wow" data-splitting>maintenant de<br/> <b className="back-color">votre projet</b>.</h2>
                            </div>
                        </div>

                        <div className="col-md-4 col-lg-3 valign">
                            <a href="mailto:contact@nerdxdigital.com" className="butn bord curve wow fadeInUp" data-wow-delay=".5s"><span>Contactez-nous maintenant</span></a>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default Expertise