import React from 'react'

const Header = () => {
    return (
        <header className="particles circle-bg valign">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="cont text-center">
                            <div className="sub-title mb-5">
                                <h6>Avec L’éco-conception web</h6>
                            </div>
                            <h1>Nous accompagnons votre transformation
                                digitale de mannière éco-responsable.
                            </h1>
                            <a href="" onClick={(e) => {e.preventDefault()}} className="butn bord curve mt-30 mr-md-4">
                                <span>Avez-vous un projet à l'horison ?</span>
                            </a>
                            <a href="" onClick={(e) => {e.preventDefault()}} className="butn bord curve mt-30 mr-md-4">
                                <span>Voulez-vous un audit gratuit ?</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="gradient-circle"></div>
                <div className="gradient-circle two"></div>
            </div>
            <div className="line bottom right"></div>
        </header>
    )
}

export default Header